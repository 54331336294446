.search {
    margin-bottom: 1.5rem;
    &__form {
        display: flex;
        flex-direction: row;
        &__input {
            width: 80%;
            margin-right: 0.5rem;
        }
        &__button {

        }
    }
}