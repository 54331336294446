// tabler bug fix on the current build.
// $table-cell-padding: 0;
@import "~tabler/scss/tabler";
@import "~tabler/scss/tabler-buttons";

@import "./objects/objects.table";

@import "./components/components.search-bar";
@import "./components/components.customer-details";
@import "./icons.scss";

.dashboard__cardbox {
    &__number {
        font-size: 1.2rem;
        font-weight: 300;
    }
}
.pagination {
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.multi-select {
    & > option {
        padding: 0.5rem;
        color: $primary;
    }
}
