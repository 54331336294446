.table {
    & > th,
    & > td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    & > thead > tr > th {
        font-size: 0.8rem !important;
        font-weight: light !important;
    }
}
