.card-profile {
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;

    & > .card-header {
        height: 9rem;
        background-size: cover;
    }
    &__title {
        font-size: 1.2rem;
        font-weight: 500;
    }

    & .balance__overlay {
        color: white;
        background-color: #7b84ff42;
        margin-top: -5rem;
        margin-bottom: 1rem;
        padding: 5px;
        border-radius: 100%;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
}

.customer_details {
    &__stamp {
        &__container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    &__stampcard {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        width: 30%;

        &__body {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
        }

        &__content {
            width: auto;
            align-items: center;
            align-content: center;
            margin-bottom: 0.5rem;
        }

        &__badge {
            width: 100%;
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & > .badge {
                display: block;
                margin-bottom: 2px;
                width: 30%;
            }
        }

        &__text {
            font-size: 0.725rem;
            display: block;
            font-weight: 600;
        }

        &__text--muted {
            font-size: 0.7rem;
            color: #6e7582;
            display: block;
            font-weight: 400;
        }

        &__text--white {
            font-size: 1rem;
            color: #fff;
            display: block;
            font-weight: bolder;
        }
    }
}

.profile__update__modal {
    & .form-label {
        margin: 0;
    }
    & .form-group {
        margin-bottom: 0.5rem;
    }
}
