.icon-view-details {
    background-color: $primary;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/eye.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/eye.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-edit {
    background-color: $primary;
    fill: currentColor;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/pencil-square.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/pencil-square.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-go-back {
    background-color: white;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/arrow-left-circle.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/arrow-left-circle.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-delete {
    background-color: $danger;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/trash.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/trash.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-x {
    background-color: $danger;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/x-circle.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/x-circle.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-check {
    background-color: $green;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/check-circle.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/check-circle.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-add {
    background-color: white;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/plus-circle.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/plus-circle.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-loyalty-rules {
    background-color: $secondary;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/bootstrap-icons/icons/collection-fill.svg");
    mask-image: url("./../../../node_modules/bootstrap-icons/icons/collection-fill.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon-member {
    background-color: rgba(72, 121, 224, 0.815);
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/tabler-icons/icons/credit-card.svg");
    mask-image: url("./../../../node_modules/tabler-icons/icons/credit-card.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.icon-email {
    background-color: rgba(72, 121, 224, 0.815);
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/tabler-icons/icons/mail.svg");
    mask-image: url("./../../../node_modules/tabler-icons/icons/mail.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.icon-phone {
    background-color: rgba(72, 121, 224, 0.815);
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/tabler-icons/icons/phone.svg");
    mask-image: url("./../../../node_modules/tabler-icons/icons/phone.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.icon-male {
    background-color: rgba(72, 121, 224, 0.815);
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/tabler-icons/icons/man.svg");
    mask-image: url("./../../../node_modules/tabler-icons/icons/man.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.icon-female {
    background-color: pink;
    display: inline-block;
    -webkit-mask-image: url("./../../../node_modules/tabler-icons/icons/woman.svg");
    mask-image: url("./../../../node_modules/tabler-icons/icons/woman.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.icon {
    margin-right: 0.2rem;
}
